<template>
  <header class="relative z-10 flex h-[52px] w-full items-center">
    <div class="hside ml-8 mr-10 flex grow justify-between lg:ml-20 xl:ml-28 2xl:ml-56">
      <div class="flex items-center gap-7">
        <VIcon @click="store.showHamburger ? toggleMenu() : null" :src="require('@/assets/omnia.svg')" :size="105" />
        <VIcon :src="require('@/assets/paro.svg')" :size="80" />
      </div>
      <VLottie v-if="store.showHamburger" ref="animation" class="h-7" :src="require('@/assets/icons/hamburger-menu.json')"
        @click="toggleMenu" title="Menu" />
    </div>
    <div class="hside mr-8 flex h-full justify-between pl-12 xl:mr-16">
      <AvatarUser :name="authStore.user.name + ' ' + authStore.user.surname" :role="authStore.user.jobRole" />
      <button class="px-4" @click="store.showVerticalMenu = !store.showVerticalMenu"
        @blur="store.showVerticalMenu = false" tabindex="0">
        <VIcon :src="require('@/assets/icons/vertical-menu.svg')" :size="7" />
      </button>
    </div>
  </header>
</template>

<script setup>
import { ref, watch } from "vue";
import AvatarUser from "@/components/AvatarUser.vue";
import { useStore } from "@/stores/store";
import { useAuthStore } from "@/stores/auth";

const authStore = useAuthStore();
const store = useStore();
const animation = ref(null);

const toggleMenu = () => { store.showMenu = !store.showMenu; };

watch(() => store.showMenu, () => {
  animation?.value?.setSpeed(2.5);
  if (store.showMenu) animation?.value?.playSegments([15, 35], true);
  else animation?.value?.playSegments([40, 70], true);
});
</script>

<style scoped>
header {
  border-bottom: 1px solid var(--secondary-color);
  background: var(--primary-color);
}

header>div:last-of-type {
  border-left: 1px solid var(--secondary-color);
}

.hside .v-lottie {
  width: 35px;
  cursor: pointer;
}
</style>
