<template>
  <div ref="el" class="masonry-item flex items-center justify-center" :id="'item-' + props.id">
    <div class="w-full item-content relative flex cursor-pointer flex-col mb-[25px] border-b border-[#d9d9d94d]"
      @click="store.selectionMode ? onSelect() : emit('click', false)">
      <div class="image relative mb-[15px] w-full overflow-hidden rounded-sm">
        <Transition name="item-checkbox">
          <div v-show="store.selectionMode" class="check absolute z-[1] flex h-8 w-8 rounded-br-lg bg-black">
            <Checkbox class="ml-1 mt-1 h-[18px] w-[18px] bg-black before:h-[10px] before:w-[10px]" v-model="checked" />
          </div>
        </Transition>
        <img ref="imgEl" class="h-auto w-full" src="@/assets/default.png" :alt="title" />
        <Transition name="pano-button" appear>
          <button v-if="img && category == 'Archivio cartografico' && showPanoButton" type="button"
            @click.stop="store.selectionMode ? null : emit('click', true)"
            class="bg-white rounded-full absolute bottom-2 right-2 p-[6px] shadow-xl hover:scale-105 transition-transform">
            <VIcon :src="require('@/assets/icons/pano.svg')" :size="20" />
          </button>
          <button v-else-if="url3D && category == 'Reperti'" type="button"
            @click.stop="store.selectionMode ? null : emit('click', true)"
            class="flex items-center justify-center w-8 h-8 bg-white rounded-full absolute bottom-2 right-2 shadow-xl hover:scale-105 transition-transform">
            <VIcon :src="require('@/assets/icons/3d.svg')" :size="20" />
          </button>
        </Transition>
      </div>
      <div class="category ml-1 mb-1 flex items-center gap-2">
        <div class="min-h-[12px] min-w-[12px] rounded-sm" :style="{ backgroundColor: '#' + color }"></div>
        <p class="text-[11px] tracking-011 uppercase opacity-50">{{ category }}</p>
      </div>
      <p v-if="title" class="mb-5 text-sm">{{ abbreviatedTitle }}</p>
    </div>
  </div>
</template>
<script setup>
import Checkbox from "@/components/Checkbox.vue";
import { useStore } from "@/stores/store";
import { ref, onMounted, watch, computed } from "vue";

const props = defineProps({
  id: {
    type: Number,
    required: true,
  },
  title: { required: true },
  img: String,
  url3D: String,
  color: String,
  category: String,
  selected: { type: Boolean }
});

const emit = defineEmits(["click", "select", "loaded", "added"]);
const store = useStore();
const checked = ref(props.selected);
const imgEl = ref(null)
const el = ref(null)
const showPanoButton = ref(false)

const abbreviatedTitle = computed(() => {
  return props.title?.length > 70 ? props.title.substring(0, 70) + "..." : props.title
});

watch(() => props.selected, (newV) => { checked.value = newV })

const onSelect = () => {
  checked.value = !checked.value;
  emit("select", { id: props.id, selected: checked.value });
};

const loadImage = () => {
  if (props.img && !!imgEl.value) {
    imgEl.value.classList.add("animate-pulse");
    const img = new Image();
    img.src = props.img;
    emit('added', el.value)

    img.onload = () => {
      if (imgEl.value) {
        imgEl.value.src = img.src;
        imgEl.value.classList.remove("animate-pulse");
        const observer = new ResizeObserver(() => {
          emit('loaded')
          setTimeout(() => { showPanoButton.value = true }, 200);
        })
        observer.observe(imgEl.value)
      }
    }
    img.onerror = () => {
      if (imgEl.value) imgEl.value.classList.remove("animate-pulse");
    }
  } else {
    emit('added', el.value)
  }
}

onMounted(() => { loadImage() })
</script>
<style>
.item .item-content {
  border-bottom: 1px solid var(--primary-light-color);
}

.masonry-item .item-content .image img {
  -webkit-user-drag: none;
  transition: opacity 0.3s ease;
  opacity: 0.9;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  -ms-interpolation-mode: nearest-neighbor;
}

.masonry-item .item-content:hover img {
  opacity: 1;
  filter: brightness(1.03);
  transition: opacity 0.3s ease;
}
</style>
