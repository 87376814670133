export const MAP_LAYERS = [
    {
        'group:': true,
        'layers': [
            {
                'url': 'https://api.mapbox.com/styles/v1/visivalab/clk81w9sx00m501qrd62c4gsh/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoidmlzaXZhbGFiIiwiYSI6ImNrOWNxamFhcjA0djMzZWsybTA1ODJ0dmMifQ.DDiyj5ImiiUbj90hTKudHA',
                'config': { maxZoom: 24, maxNativeZoom: 22 }
            }
        ]
    },
    {
        'group': true,
        'layers': [
            {
                'url': 'https://api.mapbox.com/styles/v1/visivalab/clk29ojsp00en01pffdrh0e8m/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoidmlzaXZhbGFiIiwiYSI6ImNrOWNxamFhcjA0djMzZWsybTA1ODJ0dmMifQ.DDiyj5ImiiUbj90hTKudHA',
                'config': { maxZoom: 24, maxNativeZoom: 23 },
            },
            {
                'url': 'https://d4mbo3uuph9yj.cloudfront.net/tilesets/church/{z}/{x}/{y}.png',
                'config': { maxZoom: 24, maxNativeZoom: 23 }
            }
        ]
    },
    {
        'group': true,
        'layers': [
            {
                'url': 'https://api.mapbox.com/styles/v1/visivalab/clk81w9sx00m501qrd62c4gsh/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoidmlzaXZhbGFiIiwiYSI6ImNrOWNxamFhcjA0djMzZWsybTA1ODJ0dmMifQ.DDiyj5ImiiUbj90hTKudHA',
                'config': { maxZoom: 24, maxNativeZoom: 23 },
            },
            {
                'url': 'https://d4mbo3uuph9yj.cloudfront.net/tilesets/dem/{z}/{x}/{y}.png',
                'config': { maxZoom: 24, maxNativeZoom: 23 }
            }
        ]
    },
    {
        'group': true,
        'layers': [
            {
                'url': 'https://api.mapbox.com/styles/v1/visivalab/clkb0muom004101phhs1nfgq1/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoidmlzaXZhbGFiIiwiYSI6ImNrOWNxamFhcjA0djMzZWsybTA1ODJ0dmMifQ.DDiyj5ImiiUbj90hTKudHA',
                'config': { maxZoom: 24, maxNativeZoom: 23 }
            }
        ]
    },
]
