<template>
  <div class="vertical-menu absolute right-0 top-0 z-[6] w-[368px] xl:w-[400px] bg-primary">
    <ul class="w-full">
      <!-- <li class="cursor-pointer select-none list-none px-6 py-5 text-[12px] font-medium uppercase tracking-022 transition-colors duration-200 ease-out">Il tuo profilo</li>
      <li class="cursor-pointer select-none list-none px-6 py-5 text-[12px] font-medium uppercase tracking-022 transition-colors duration-200 ease-out">Gestione Utenti</li> -->
      <li class="flex cursor-pointer select-none list-none items-center gap-3 pl-[52px] px-6 py-4 text-[12px] font-medium uppercase tracking-022 transition-colors duration-200 ease-out" @mousedown="logout">
        <VIcon :src="require('@/assets/icons/logout.svg')" />Esci</li>
    </ul>
  </div>
</template>
<script setup>
import { useAuthStore } from "@/stores/auth";
import { useStore } from "@/stores/store";

const store = useStore()
const logout = () => {
  useAuthStore().logout();
  store.showVerticalMenu = false
};
</script>
<style scoped>
.vertical-menu {
  border-bottom: 1px solid var(--secondary-color);
  border-left: 1px solid var(--secondary-color);
}

.vertical-menu li:hover {
  background: var(--primary-light-color);
}
</style>
