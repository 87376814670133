import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createPinia } from 'pinia'
import piniaPluginPersistedState from 'pinia-plugin-persistedstate'
import { VIcon, VLottie, VMap } from 'visivalab-components'

// tabs
import SchedaTab from './components/tabs/SchedaTab.vue'
import CollegamentiTab from './components/tabs/CollegamentiTab.vue'

import 'visivalab-components/dist/visivalab-components.css'
import './assets/style.css'
import './assets/transitions.css'
import './registerServiceWorker'
import { axiosInstanceWithAuth } from './utils'
import { useAuthStore } from './stores/auth'
import 'photoswipe/style.css';

// Vuetify
// import 'vuetify/styles'
// import { createVuetify } from 'vuetify'
// import * as components from 'vuetify/components'
// import * as directives from 'vuetify/directives'

// import {Vuetify} from 'vuetify';
// import 'vuetify/dist/vuetify.min.css';

import PrimeVue from 'primevue/config';
import { usePassThrough } from "primevue/passthrough";
import Tailwind from "primevue/passthrough/tailwind";


const pinia = createPinia()
pinia.use(piniaPluginPersistedState)
const authStore = useAuthStore(pinia)

axiosInstanceWithAuth.get('/api/user').then((res) => {
    if (res.status === 200) {
        authStore.authenticated = true
        authStore.user = res.data
    }
}).catch(() => {
    authStore.authenticated = false
}).finally(() => {
    let app = createApp(App)
    app.use(pinia)
    app.use(router)

    const CustomTailwind = usePassThrough(
        Tailwind,
        {
            button: {
                root: {
                    class: ['rounded-none border border-white dark:border-white bg-black dark:bg-black hover:bg-white dark:hover:bg-white hover:text-black dark:hover:text-black hover:border-white dark:hover:border-white']
                },
                label: {
                    class: ['font-normal']
                }
            },
            inputtext: {
                root: {
                    class: ['tracking-wide font-light font-roboto text-white/80 bg-zinc-900 dark:bg-zinc-900 border-zinc-900 dark:border-zinc-900 hover:border-zinc-500 dark:hover:border-zinc-500 focus:shadow-zinc-500 dark:focus:shadow-zinc-500']
                }
            },
            textarea: {
                root: {
                    class: ['tracking-wide font-light font-roboto text-white/80 bg-zinc-900 dark:bg-zinc-900 border-zinc-900 dark:border-zinc-900 hover:border-zinc-500 dark:hover:border-zinc-500 focus:shadow-zinc-500 dark:focus:shadow-zinc-500']
                }
            },
            dropdown: {
                root: {
                    class: ['bg-zinc-900 dark:bg-zinc-900 border-zinc-900 dark:border-zinc-900 hover:border-zinc-500 dark:hover:border-zinc-500']
                },
                input: {
                    class: ['text-white/80 dark:text-white/80']
                },
                trigger: ({ state }) => ({
                    class: ['[&>svg]:transition-transform', { '[&>svg]:rotate-180': state.overlayVisible }]
                }),
                list: {
                    class: ['bg-zinc-800']
                },
                item: ({ context }) => ({
                    class: ['hover:bg-zinc-700 dark:hover:bg-zinc-700 hover:text-white dark:hover:text-white',
                        {
                            'text-white/80': !context.focused && !context.selected,
                            'bg-zinc-700/75 text-white/80 dark:text-white/80 dark:bg-zinc-700/75': context.focused && !context.selected,
                            'bg-zinc-400 text-white/80 dark:bg-zinc-400 dark:text-white/80': context.focused && context.selected,
                            'bg-zinc-300 text-white/80 dark:bg-zinc-300 dark:text-white/80': !context.focused && context.selected
                        }]
                })
            },
            calendar: {
                root: {
                    class: ['p-calendar']
                },
                input: {
                    class: ['w-full outline-none dark:outline-none bg-zinc-900 dark:bg-zinc-900 border-zinc-800 dark:border-zinc-800 hover:border-zinc-500 dark:hover:border-zinc-500 focus-visible:border-zinc-500 dark:focus-visible:border-zinc-500']
                },
                panel: {
                    class: ['bg-zinc-900 dark:bg-zinc-900']
                },
                header: {
                    class: ['bg-zinc-900 dark:bg-zinc-900']
                },
            },
            multiselect: {
                root: {
                    class: ['w-full md:w-full bg-zinc-900 dark:bg-zinc-900 border-zinc-900 dark:border-zinc-900 hover:border-zinc-500 dark:hover:border-zinc-500']
                },
                trigger: ({ state }) => ({
                    class: ['[&>svg]:transition-transform', { '[&>svg]:rotate-180': state.overlayVisible }]
                }),
                header: {
                    class: ['bg-zinc-800 dark:bg-zinc-800']
                },
            }
        },
        { merge: true, useMergeProps: true }
    );
    app.use(PrimeVue, { unstyled: true, pt: CustomTailwind });

    // tabs
    app.component('SchedaTab', SchedaTab)
    app.component('CollegamentiTab', CollegamentiTab)

    // common components
    app.component('VIcon', VIcon)
    app.component('VLottie', VLottie)
    app.component('VMap', VMap)
    app.mount('#app')
})
