<template>
  <div class="w-full"></div>
</template>

<script>
import { useStore } from '@/stores/store';
import {onMounted, onBeforeUnmount } from 'vue'

export default {
  name: 'HomeView',
  setup() {
    const store = useStore();
    onMounted(() => {
      store.showMenu = true;
      store.showHamburger = false;
    })
    onBeforeUnmount(() => {
      store.showMenu = false;
      store.showHamburger = true;
    })
  }
}
</script>