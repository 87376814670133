<template>
  <Accordion v-for="(item, index) in props.items" :key="index" v-show="item.data.filter(d => d.value).length">
    <template v-slot:title>
      {{ item.title }}
    </template>
    <template v-slot:content>
      <AccordionDataDisplay :data="item.data" v-if="item.data.length" />
    </template>
  </Accordion>
</template>
<script setup>
import Accordion from "./Accordion.vue";
import AccordionDataDisplay from "./AccordionDataDisplay.vue";

const props = defineProps({ items: { type: Array, default: () => [] } });
</script>
<style></style>