import { defineStore } from 'pinia'
import router from '@/router'
import { axiosInstanceWithAuth } from '@/utils'
import { errorMessage } from "../utils/network";
import { gsap } from 'gsap';

export const useAuthStore = defineStore({
  id: 'auth',
  state: () => ({
    authenticated: null,
    user: {
      id: null,
      name: null,
    },
    error: null,
    loading: false,
  }),
  getters: {
    isAuthenticated() {
      return this.authenticated
    },
  },
  actions: {
    login(email, password) {
      this.error = null
      this.loading = true
      axiosInstanceWithAuth.get('sanctum/csrf-cookie').then(() => {
        axiosInstanceWithAuth.post('/api/login', { email: email, password: password}).then(async (res) => {
          this.authenticated = true
          this.user = res.data.user
          gsap.to('.login', { opacity: 0, scale: 1.05, duration: 0.4, onComplete: async () => {
              if (router.currentRoute.value.redirectedFrom) router.push(router.currentRoute.value.redirectedFrom)
              else await router.push({ name: 'home' })
          }})
        }).catch((error) => {
          this.error = errorMessage(error);
        }).finally(() => {
          this.loading = false
        })
      })
    },
    async logout() {
      if (this.authenticated) {
        axiosInstanceWithAuth.post('/api/logout').then(() => {
          this.authenticated = false
          router.push('/login')
        })
      }
    },
  }
})
