import axios from "axios";
import { useAuthStore } from "./stores/auth";

let url = process.env.VUE_APP_API_URL;

export const axiosInstanceWithAuth = axios.create({
    baseURL: url,
    withXSRFToken: true,
    withCredentials: true,
    headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        'X-Requested-With': 'XMLHttpRequest',
    }
});
axiosInstanceWithAuth.defaults.timeout = 200000;

// handle 401 errors
axiosInstanceWithAuth.interceptors.response.use(
    response => response,
    error => {
        if (error.response.status === 401 || error.response.status === 419) {
            useAuthStore().logout();
        }

        return Promise.reject(error);
    }
);


// sheets actions
export function handleAction(data, openSheet, closeSheet) {
    if (data.type === 'openSheet') openSheet({
        id: data.payload.element.id,
        table: data.payload.sheet.table,
    })
    else if (data.type === 'closeSheet') closeSheet(data.payload)
}