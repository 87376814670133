<template>
  <section
    class="login overflow-hidden flex h-full w-full bg-cover bg-no-repeat text-[12px] text-white md:bg-[url('../assets/login-background.jpg')] 2xl:bg-contain">
    <div
      class="side absolute right-0 flex h-full w-full min-w-max max-w-3xl items-center justify-center bg-black px-10 md:w-5/12">
      <div class="content flex flex-col items-center gap-12">
        <div class="logos mr-40 flex select-none flex-col items-end gap-5 self-start">
          <VIcon class="w-[300px]" :src="require('../assets/omnia.svg')" :size="240" />
          <VIcon class="w-[157px]" :src="require('../assets/paro.svg')" :size="100" />
        </div>
        <Transition name="login-form" mode="out-in">
          <form class="flex flex-col gap-4 self-end" v-if="!showRecovery" @submit.prevent="login">
            <span v-if="authStore.error" class="absolute -mt-7 text-xs text-error">{{ authStore.error }}</span>
            <input
              class="w-80 border border-neutral-500 bg-transparent px-6 py-[13px] tracking-[.22em] outline-none transition-colors duration-300 focus:border-white"
              type="email" placeholder="Email" v-model="email" :class="{ 'animate-shake': warn == 1 }" :disabled="authStore.loading" autocomplete="email" />
            <input
              class="w-80 border border-neutral-500 bg-transparent px-6 py-3 tracking-[.22em] outline-none transition-colors duration-300 focus:border-white"
              type="password" placeholder="Password" v-model="password" :class="{ 'animate-shake': warn == 2 }" :disabled="authStore.loading" autocomplete="current-password" />
            <button
              class="text-left text-xs uppercase tracking-[.22em] text-neutral-300 outline-none hover:text-white focus:text-white focus:underline"
              type="button" @click="showRecovery = true" :disabled="authStore.loading">
              Password dimenticata?
            </button>
            <button
              class="w-[110px] self-end bg-[#a3402b] px-7 py-[13px] text-[12px] font-medium uppercase tracking-[.22em] outline-none transition-colors duration-300 hover:bg-[#c14d35] focus:bg-[#c14d35]"
              type="submit" :class="{ disable: disableSubmit, 'opacity-80': authStore.loading }" :disabled="authStore.loading">
              <VLottie v-if="authStore.loading" class="h-[18px]" :src="require('@/assets/icons/spinner.json')" autoplay loop />
              <span class="h-3" v-else>Entra</span>
            </button>
          </form>
          <form v-else class="recovery flex h-[198px] flex-col gap-4 self-end" @submit.prevent="sendRecovery">
            <h2 class="text-lg font-medium">Password dimenticata</h2>
            <Transition name="login-form" mode="out-in">
              <input
                class="w-80 border border-neutral-500 bg-transparent px-6 py-3 outline-none transition-colors duration-300 focus:border-white"
                type="email" placeholder="Email" v-model="email" :class="{ 'animate-shake': warn == 1 }"
                v-if="!showRecoveryMessage" />
              <p class="max-w-xs" v-else>
                El mail ha sido enviado exitosamente. Te llegará un mail con la
                nueva password asignada.
              </p>
            </Transition>
            <div class="flex justify-between">
              <button class="w-[74px] text-left text-xs uppercase tracking-[.22em]" type="button"
                @click="showRecovery = false">
                <VIcon class="pb-2" :src="require('@/assets/icons/back-arrow-login.svg')" :size="35" />
                Torna al login
              </button>
              <button
                class="w-fit self-end bg-[#a3402b] px-7 py-[13px] text-[12px] font-medium uppercase tracking-[.22em] outline-none transition-colors duration-300 hover:bg-[#c14d35] focus:bg-[#c14d35]"
                type="submit" :class="{ disable: disableSubmit }" v-if="!showRecoveryMessage">
                Invia
              </button>
            </div>
          </form>
        </Transition>
      </div>
    </div>
  </section>
</template>

<script setup>
import { useStore } from "@/stores/store";
import { useAuthStore } from "@/stores/auth";
import { ref, onMounted, onUnmounted } from "vue";
import { gsap } from "gsap";

const store = useStore();
const authStore = useAuthStore();

const email = ref("");
const password = ref("");
const disableSubmit = ref(false);
const warn = ref(0);
const showRecovery = ref(false);
const showRecoveryMessage = ref(false);

onMounted(() => {
  store.showHeader = false;
  store.showMenu = false;
});
onUnmounted(() => {
  store.showHeader = true;
});

const sendRecovery = () => {
  if (email.value) {
    console.log(
      "peticion a API pare enviar email de recuperación de constraseña"
    );
    showRecoveryMessage.value = true;
  } else {
    warnInput(1);
  }
};

const login = async () => {
  if (email.value) {
    if (password.value) {
      try {
        disableSubmit.value = true;
        await authStore.login(email.value, password.value);
      } catch (error) {
        console.log(error);
      }
    } else {
      warnInput(2);
    }
  } else {
    warnInput(1);
  }
};

const warnInput = (n) => {
  warn.value = n;
  setTimeout(() => { warn.value = 0; }, 500);
};

onMounted(() => {
  gsap.fromTo('.login', { opacity: 0, scale: 0.95 }, { opacity: 1, scale: 1, duration: 0.4, delay: 0.2 });
})
</script>

<style>
.logos {
  -webkit-user-drag: none;
}

#content:has(.login) {
  height: 100%;
}
</style>
