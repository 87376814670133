const DEPOT_STROKE_COLOR = '#758650';

export const getPolygonStyles = (zoomLevel) => {
    const calculateWeight = (baseWeight) => {
        return Math.floor(baseWeight * (zoomLevel / 30));
    };

    return {
        background: {
            color: '#706C52',
            fillColor: '#706C52',
            fillOpacity: 0.3,
            weight: calculateWeight(1),
        },
        selected: {
            color: '#E8831C',
            fillColor: '#E8831C',
            fillOpacity: 1,
            opacity: 1,
            weight: calculateWeight(5),
        },
        transparent: {
            color: '#706C52',
            fillColor: '#706C52',
            fillOpacity: 0,
            weight: calculateWeight(1),
        },
        depot: {
            fillColor: '#E3E7DC',
            color: "#5D752C",
            weight: calculateWeight(4),
            opacity: 1,
            fillOpacity: 1,
        },
        hoverDepot: {
            fillColor: '#B5C3A6',
            color: "#5D752C",
            weight: calculateWeight(4),
            opacity: 1,
            fillOpacity: 1,
        },
        focusedDepot: {
            fillColor: '#ffffff',
            color: "#5D752C",
            weight: calculateWeight(4),
            opacity: 1,
            fillOpacity: 1,
        },
        depotEntity: {
            fillColor: DEPOT_STROKE_COLOR,
            color: "#5D752C",
            weight: calculateWeight(3),
            opacity: 0.8,
            fillOpacity: 0.2,
        },
        wall: { // temporary
            fillColor: DEPOT_STROKE_COLOR,
            color: "#5D752C",
            weight: calculateWeight(3),
            opacity: 1,
            fillOpacity: 0.7,
        },
        child: {
            fillColor: DEPOT_STROKE_COLOR,
            color: "#5D752C",
            weight: calculateWeight(2),
            opacity: 0.6,
            fillOpacity: 0.2,
        },
        hoverChild: {
            fillColor: DEPOT_STROKE_COLOR,
            color: "#5D752C",
            weight: calculateWeight(3),
            opacity: 1,
            fillOpacity: 0.5,
        },
        selectedNodeChild: {
            fillColor: DEPOT_STROKE_COLOR,
            color: "#5D752C",
            weight: calculateWeight(4),
            opacity: 1,
            fillOpacity: 0.2,
        },
        environment: {
            fillColor: "#FFFF00",
            color: "#B2B200",
            weight: calculateWeight(1),
            opacity: 0.4,
            fillOpacity: 0.2,
        },
        topographicElement4: {
            fillColor: "#FF00FF",
            color: "#B200B2",
            weight: calculateWeight(1),
            opacity: 0.2,
            fillOpacity: 0.2,
        },
        monument: {
            color: "#D1A532",
            weight: 1,
            opacity: 1,
            fillOpacity: 0.2,
            fillColor: "#FBD77B"
        }
    }
};

export const LEAFLET_CONFIG = {
    minZoom: 17,
    maxZoom: 24,
    fitBoundsPadding: 0.003
}