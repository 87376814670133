import { defineStore } from 'pinia'

export const useStore = defineStore('app', {
    state: () => ({
        showMenu: false,
        showHeader: true,
        showVerticalMenu: false,
        showHamburger: true,
        selectionMode: false,
        areas: [],
        depotAreas: [],
    }),
    getters: {
        filteredItems: (state) => {
            if (!state.items) return []
            state.processedItems = state.items.filter((item) =>
                item.definition.toLowerCase().includes(state.search.toLowerCase().trim())
            );
            return state.processedItems.slice((state.currentPage - 1) * state.itemsPerPage, state.currentPage * state.itemsPerPage)
        },
    },
    actions: {
        async getData() {
        },
        randomImage() {
        },
        nextPage() {
            if (this.currentPage < this.numPages) this.currentPage += 1
        },
        updateCurrentPage(num) {
            this.currentPage = num
        },
        closeMenu() {
            this.showMenu = false
        }
    }
})