<template>
  <div class="menu absolute z-[5] flex h-full w-full flex-col bg-primary pl-8 lg:pl-28 xl:pl-44 2xl:pl-56">
    <div class="menu-section my-5 mt-10 flex max-w-5xl flex-col gap-3">
      <h2 class="text-xl font-normal">Documentazione</h2>
      <div class="button-group flex flex-wrap gap-4" v-if="docButtons.length">
        <button @click="openDocumentazione(button.table)"
          class="flex h-[56px] w-[205px] items-center gap-[10px] border border-white px-3 text-left text-[12px] uppercase leading-[15px] tracking-011 text-white transition-colors hover:bg-neutral-800"
          type="button" v-for="button in tmpDocButtons" :key="button.table">
          <div class="min-h-[12px] min-w-[12px] rounded-sm" :style="{ backgroundColor: '#' + button.color }"></div>
          <VIcon :src="button.iconURL ? require('@/assets/icons/' + button.iconURL) : null" />
          {{ button.buttonTitle }}
        </button>
      </div>
    </div>
    <div class="menu-section my-5 flex flex-col gap-3">
      <h2 class="text-xl font-normal">Divulgazione</h2>
      <div class="button-group flex flex-wrap gap-4">
        <button
          class="flex h-[56px] w-[205px] items-center gap-[10px] border border-white px-3 text-left text-[12px] uppercase leading-[14px] tracking-011 text-white transition-colors cursor-not-allowed"
          type="button" v-for="button in divButtons" :key="button.id">
          <div class="min-h-[12px] min-w-[12px] rounded-sm" :style="{ backgroundColor: '#' + button.color }"></div>
          <VIcon :src="button.icon ? require('@/assets/icons/' + button.icon) : null" />
          {{ button.title }}
        </button>
      </div>
    </div>
    <div class="menu-section my-5 flex flex-col gap-3">
      <h2 class="text-xl font-normal">Area Archeologica</h2>
      <div class="button-group flex w-[580px] flex-wrap gap-4 xl:w-auto">
        <button
          class="border border-white px-6 py-[12px] text-[12px] uppercase tracking-011 text-white transition-colors hover:bg-neutral-800"
          type="button" v-for="button in store.areas" :key="button.id" @click="openAreaArcheologica(button)">
          {{ button.label }}
        </button>
      </div>
    </div>
    <div class="menu-section my-5 flex flex-col gap-3">
      <h2 class="text-xl font-normal">Depositi</h2>
      <div class="button-group flex w-[580px] flex-wrap gap-4 xl:w-auto">
        <button
          class="border border-white px-6 py-[12px] text-[12px] uppercase tracking-011 text-white transition-colors hover:bg-neutral-800"
          type="button" v-for="button in docStore.getAreasWithDepotsDropdown" :key="button.id"
          @click="openDeposito(button)">
          {{ button.name }}
        </button>
      </div>
    </div>
    <div class="absolute bottom-14 right-28 flex flex-col gap-6 xl:flex-row">
      <a class="flex items-center bg-neutral-300 px-7 py-[13px] text-[12px] font-medium uppercase tracking-022 transition-colors hover:bg-white"
        href="https://2.42.229.113/Palladium/login" target="_blank">Palladium</a>
      <a class="flex items-center bg-neutral-300 px-7 py-[13px] text-[12px] font-medium uppercase tracking-022 transition-colors hover:bg-white"
        v-if="signumVisible" href="http://10.1.2.249/" target="_blank">Signum</a>
    </div>
  </div>
</template>

<script setup>
import { onBeforeMount, ref } from "vue";
import { useRouter } from "vue-router";
import { useDocumentationStore } from "@/stores/documentation";
import { useStore } from "@/stores/store";
import { axiosInstanceWithAuth } from "@/utils";
import { bbox } from "@turf/turf";
import { gsap } from "gsap";
import axios from "axios";

const emit = defineEmits(["select"]);
const router = useRouter();
const docStore = useDocumentationStore();
const store = useStore()

const docButtons = ref([]);
const divButtons = ref([]);
const tmpDocButtons = ref([]);

const signumVisible = ref(false);

function openDocumentazione(sheet) {
  router.push({ name: "documentazione" });
  docStore.setCurrentSheet(sheet);
  emit("select", sheet);
}

function openAreaArcheologica(area) {
  router.push({ name: "map" });
  docStore.currentArea = area
  emit("select", area);
}

function openDeposito(area) {
  router.push({ name: "depot" });
  docStore.currentDepotArea = area
  emit("select", area);
}

onBeforeMount(async () => {
  // check if the Signum url works
  axios.get("http://10.1.2.249/", { // this cannot work because it is http
  }).then(() => {
    signumVisible.value = true;
  }).catch((error) => {
    if (error.response || error.request) {
      console.log(error.response || error.request);
      signumVisible.value = true;
    } else {
      signumVisible.value = false;
    }
  });

  await docStore.fetchDepotAreas();
  await docStore.fetchSheetsMetadata();
  await docStore.fetchAreas();

  docButtons.value = docStore.getSheets.filter((item) => item.order);
  tmpDocButtons.value = [
    {
      table: null,
      buttonTitle: "Documentazione generale",
      color: "ffffff",
      iconURL: null,
    },
    ...docButtons.value,
  ];
  divButtons.value = (await axiosInstanceWithAuth.get("/api/rest/menuItem")).data.data.filter(
    (item) => item.function === "divulgazione"
  );


  const archaeologicalAreaButtons = (await axiosInstanceWithAuth.get("/api/rest/topographicElement1")).data.data;

  archaeologicalAreaButtons.map((item) => {
    item.label = item.name;
    item.bounds = bbox(JSON.parse(item.polygon));
    return item;
  });
  archaeologicalAreaButtons.unshift({ label: "Generale" });
  store.areas = archaeologicalAreaButtons;
  await gsap.to('.menu-section', { duration: .4, opacity: 1, stagger: .05 })
});
</script>

<style>
.menu-section {
  opacity: 0
}
</style>
