import { defineStore } from "pinia";
import { mapSheet } from "@/utils/data";
import { axiosInstanceWithAuth } from '@/utils'
import { bbox } from "@turf/turf";

function generateDropdown(stateArray, labelField, valueField, prependItem = null, maintainFields = false) {
    if (prependItem) {
        stateArray.unshift(prependItem);
    }
    return stateArray.map((item) => ({
        label: item[labelField],
        value: item[valueField],
        color: item?.color,
        ...(maintainFields ? item : {}),
    }));
}

export function extractValues(item, columns = false) {
    if (columns) {
        switch (item.category) {
            case 'Monumenti':
                return ['definizione', 'denominazione alternativa', 'tipologia', 'periodo']
            case 'Reperti':
                return ['Nº INVENTARIO', 'definizione', 'fascia cronologica di riferimento']
            case 'Archivio fotografico':
                return ['inventario', 'definizione bene', 'indicazioni sul soggetto', 'formato']
            case 'Archivio cartografico':
                return ['inventario', 'definizione', 'indicazioni sul soggetto']
            case 'Biblioteca digitale':
                return ['autore', 'titolo', 'anno']
            default:
                return ['doc', 'doc2']
        }
    }

    switch (item.category) {
        case 'Monumenti':
            return {
                'definizione': item.sheet.definition,
                'denominazione alternativa': item.sheet.alternativeDenomination,
                'tipologia': item.sheet.monumentType?.name,
                'periodo': item.sheet.timePeriod?.name
            }
        case 'Reperti':
            return {
                'Nº INVENTARIO': item.sheet.raInventory ? item.sheet.raInventory[0].number : '',
                'definizione': item.sheet.publicTitle,
                'fascia cronologica di riferimento': item.sheet.referenceChronologicalSlotFraction ? (
                    item.sheet.referenceChronologicalSlot?.name
                    + "/" + item.sheet.referenceChronologicalSlotFraction.name) : ''
            }
        case 'Archivio fotografico':
            return {
                'inventario': item.sheet.photoInventory.reduce((acc, curr) => acc + curr.code + ', ', '').slice(0, -2),
                'definizione bene': item.sheet.name,
                'indicazioni sul soggetto': item.sheet.subject,
                'formato': item.sheet.format,
            }
        case 'Archivio cartografico':
            return {
                'inventario': item.sheet.drawingInventory.reduce((acc, curr) => acc + curr.code + ', ', '').slice(0, -2),
                'definizione': item.sheet.name,
                'indicazioni sul soggetto': item.sheet.subjectIndications.reduce((acc, curr) => acc + curr.name + ', ', '').slice(0, -2)
            }
        case 'Biblioteca digitale':
            return {
                'autore': item.sheet.authors.reduce((acc, curr) => acc + curr.name + ', ', '').slice(0, -2),
                'titolo': item.sheet.title,
                'anno': item.sheet.editionYear ? item.sheet.editionYear : ''
            }
        default:
            return { 'doc': '', 'doc2': '' }
    }
}

export const useDocumentationStore = defineStore("documentation", {
    state: () => ({
        sheets: [],
        currentSheet: null,
        elements: [],
        elementsPage: 1,
        elementsTotalPages: 1,
        elementsTotal: 0,
        areas: [],
        depotAreas: [],
        currentArea: 0,
        currentDepotArea: 0,
        isLoading: false
    }),
    getters: {
        getCurrentArea: (state) => state.currentArea,
        getAreas: (state) => state.areas,
        getDepotAreas: (state) => [...state.depotAreas, { id: 0, name: "Generale" }],
        getAreasDropdown: (state) => generateDropdown(state.areas, 'name', 'id', { id: 0, name: "Generale" }),
        getAreasWithDepotsDropdown: (state) => generateDropdown(
            state.getDepotAreas.sort((a, b) => {
                if (a.id !== b.id) {
                    return a.id - b.id;
                } else {
                    return a.name.localeCompare(b.name);
                }
            }),
            'name', 'id', null, true),
        getCurrentSheet: (state) => state.currentSheet,
        getSheets: (state) => state.sheets,
        getSheetsDropdown: (state) => {
            return generateDropdown(state.sheets, 'buttonTitle', 'table', { buttonTitle: "Documentazione generale", table: null, color: "ffffff" })
        },
        getSheetForm: (state) => (sheet) => state.sheets.find(s => s.table === sheet)?.formDropdowns,
        getSheetFormFields(sheet) {
            return this.getSheetForm(sheet)?.map((dropdown) => dropdown.formField).flat() || [];
        },
        getElements: (state) => state.elements
    },
    actions: {
        setArea(area) {
            this.currentArea = area;
        },
        async fetchSheetsMetadata() {
            if (this.sheets.length > 0) return;

            let res = await axiosInstanceWithAuth.get("/api/rest/sheet");
            this.sheets = res.data;
            this.currentSheet = this.sheets.find(item => item.color == localStorage.getItem("currentSheet"));
        },
        async fetchOptionsTables(sheet) {
            let fieldDropdowns = this.sheets.find(s => s.table === sheet).formDropdowns;

            let promises = [];

            if (fieldDropdowns) {
                for (let fd of fieldDropdowns) {
                    for (let f of fd.formField) {
                        if (f.fieldType === "select") {
                            let promise = axiosInstanceWithAuth.get("/api/rest/" + f.table)
                                .then(data => {
                                    f.tableOptions = data.data.data;
                                });
                            promises.push(promise);
                        }
                    }
                }
            }

            try {
                await Promise.all(promises);
            } catch (error) {
                console.error("An error occurred:", error);
            }
        },

        async fetchElement(id, sheet, links = false) {
            let res = await axiosInstanceWithAuth.get("/api/rest/" + sheet + "/" + id + (links ? "?links=true" : ""));
            return res.data.data
        },
        async fetchElements(page = 1, append = false, query = null) {
            if (!this.currentSheet && !query) return;
            try {
                this.isLoading = true
                console.log(query ? "searching for " + query : "fetching elements for page " + page);
                let url = "/api/" + (this.currentSheet ? "rest/" + this.currentSheet.table : "sheet/search") + (page ? ("?page=" + page) : '');

                if (query) {
                    url += (page ? '&' : '?') + "q=" + query;
                }
                console.log(this.currentArea, typeof this.currentArea)

                if (this.currentArea && typeof this.currentArea === "number") {
                    url += (page || query ? '&' : '?') + "area=" + this.currentArea;
                }

                let res = await axiosInstanceWithAuth.get(url);

                this.elementsTotal = res.data.meta ? res.data.meta.total : res.data.data.length;

                if (append) {
                    this.elements = [...this.elements, ...res.data.data.map((element) => mapSheet(
                        element,
                        this.currentSheet ? this.currentSheet.table : element.sheet.table,
                        this.currentSheet ? this.currentSheet.color : element.sheet.color,
                        this.currentSheet ? this.currentSheet.buttonTitle : element.sheet.buttonTitle
                    ))];
                } else {
                    this.elements = res.data.data.map((element) => mapSheet(element,
                        this.currentSheet ? this.currentSheet.table : element.sheet.table,
                        this.currentSheet ? this.currentSheet.color : element.sheet.color,
                        this.currentSheet ? this.currentSheet.buttonTitle : element.sheet.buttonTitle
                    ))
                }

                if (page) {
                    //this.elementsPage = res.data.meta.current_page;
                    this.elementsTotalPages = res.data.meta.last_page;
                }

                let fields = this.getSheetFormFields;

                // depending on the sheet type, the title field is set differently
                // TODO: do this using the sheet metadata
                if (!append) {
                    // for each field, the tables for the dropdown menus are fetched
                    for (let f of fields) {
                        if (f.fieldType === "select") {
                            let data = await axiosInstanceWithAuth.get("/api/rest/" + f.table);
                            f.tableOptions = data.data.data;
                        }
                    }
                }

                // Add property selected to every element
                this.elements.forEach(element => { if (!element.selected) element.selected = false })
            } catch (error) {
                console.log(error);
            } finally {
                this.isLoading = false
            }
        },
        async fetchNextPage(query = null) {
            this.elementsPage++;
            console.log("fetching next page", this.elementsPage);
            if (this.elementsPage >= this.elementsTotalPages) return;
            await this.fetchElements(this.elementsPage, true, query);
        },
        async setCurrentSheet(sheet) {
            // if the sheet is not specified, we are opening "documentazione generale"
            if (sheet) {
                this.currentSheet = this.sheets.find(item => item.table == sheet);
                localStorage.setItem("currentSheet", this.currentSheet.color)
            } else {
                this.currentSheet = null;
                localStorage.setItem("currentSheet", null)
            }

            // reset elements and pagination
            this.elements = [];
            this.elementsPage = 1;
            this.elementsTotalPages = undefined;
        },
        async fetchDepotAreas() {
            let res = await axiosInstanceWithAuth.get("/api/rest/depotArea");
            this.depotAreas = res.data.data;
            this.depotAreas.forEach(area => {
                area.bounds = bbox(JSON.parse(area.polygon));
            })
            console.log(this.depotAreas)
        },
        async fetchAreas() {
            if (this.areas.length > 1) return;
            try {
                let data = await axiosInstanceWithAuth.get("/api/rest/topographicElement1");
                this.areas = data.data.data;
                console.log(this.areas)

                this.areas.forEach(area => {
                    area.bounds = bbox(JSON.parse(area.polygon));
                })
            } catch (error) {
                console.log(error);
            }
        },
        async fetchTabs(sheet) {
            let res = await axiosInstanceWithAuth.get("/api/rest/" + sheet + "/tabs");

            return res.data.sort((a, b) => a.order - b.order);
        },
        setElementAttribute(id, name, value) {
            this.elements.find(element => element.id == id)[name] = value
        },
        selectAllElements() {
            this.elements.forEach(el => { el.selected = true });
        },
        unselectAllElements() {
            this.elements.forEach(el => { el.selected = false });
        }
    }
})