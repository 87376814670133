import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginView from '../views/LoginView.vue'
import { useAuthStore } from '@/stores/auth';

const routes = [
  {
    path: '',
    name: 'home',
    component: HomeView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
  },
  {
    path: '/documentazione',
    name: 'documentazione',
    component: () => import('../views/DocumentazioneView.vue')
  },
  {
    path: '/mappa',
    name: 'map',
    component: () => import('../views/AreaArcheologicaView.vue'),
    props: true
  },
  {
    path: '/deposito',
    name: 'depot',
    component: () => import('../views/DepositoView.vue'),
    props: true
  },
  // service routes
  {
    path: '/deposito-grafo',
    name: 'depot-graph',
    component: () => import('../views/DepositoGraphView.vue'),
    props: true
  },
  {
    path: '/spreadsheet',
    name: 'spreadsheet',
    component: () => import('../views/SpreadsheetView.vue'),
    props: true
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const isAuthenticated = useAuthStore().isAuthenticated

  if (to.name !== 'login' && !isAuthenticated) {
    next({
      name: 'login',
      query: { redirectTo: to.fullPath },
    });
  } else if (to.name === 'login' && isAuthenticated) {
    next({ name: 'home' });
  } else {
    next();
  }
});

export default router