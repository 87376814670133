<template>
  <div class="accordion w-full">
    <div
      class="title flex w-full cursor-pointer justify-between px-[6px] pb-[30px] pt-[20px] text-left text-sm font-medium uppercase tracking-011"
      @click="openPanel()">
      <slot name="title"></slot>
      <VLottie ref="animation" class="mr-5 w-[13px]" :src="require('@/assets/icons/plus-minus.json')" />
    </div>
    <div class="h-0 overflow-hidden px-[6px]" ref="panel">
      <slot name="content"></slot>
    </div>
  </div>
</template>
<script setup>
import { ref } from "vue";
import { gsap } from "gsap";

const active = ref(false);
const animation = ref(null);
const panel = ref(null);

const openPanel = () => {
  active.value = !active.value;
  if (active.value) {
    gsap.set(panel.value, { display: "block" });
    gsap.to(panel.value, { duration: 0.3, height: "auto", opacity: 1 });
    animation.value.playSegments([0, 30], true);
  } else {
    gsap.to(panel.value, { duration: 0.3, height: 0, opacity: 0, onComplete: () => { gsap.set(panel.value, { display: "none" }); } });
    animation.value.playSegments([40, 60], true);
  }
};

defineExpose({ openPanel, active });
</script>
<style scoped>
.accordion .title {
  color: var(--secondary-color);
}

.accordion {
  border-bottom: 1px solid var(--secondary-color);
}
</style>
