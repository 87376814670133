<template>
    <input type="checkbox" name="checkbox" :checked="isChecked" :value="value" @change="updateInput" />
</template>

<script setup>
import { computed } from 'vue'
const props = defineProps({
  value: String,
  modelValue: {},
  trueValue: { default: true },
  falseValue: { default: false }
})
const emit = defineEmits(['change', 'update:modelValue'])

const updateInput = (event) => {
  let isChecked = event.target.checked
  if (props.modelValue instanceof Array) {
    let newValue = [...props.modelValue]
    if (isChecked) {
      newValue.push(props.value)
    } else {
      newValue.splice(newValue.indexOf(props.value), 1)
    }
    emit('change', newValue)
    emit('update:modelValue', newValue)
  } else {
    emit('change', isChecked ? props.trueValue : props.falseValue)
    emit('update:modelValue', isChecked ? props.trueValue : props.falseValue)
  }
}

const isChecked = computed(() => {
  if (props.modelValue instanceof Array) {
    return props.modelValue.includes(props.value)
  }
  return props.modelValue === props.trueValue
})
</script>

<style scoped>
input[type="checkbox"] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  appearance: none;
  /* For iOS < 15 to remove gradient background */
  background-color: var(--primary-color);
  /* Not removed via appearance */
  /* margin: 0; */
  font: inherit;
  color: currentColor;
  border: 1px solid var(--secondary-color);
  border-radius: 4px;
  /* transform: translateY(-0.075em); */

  display: grid;
  place-content: center;
}

input[type="checkbox"]::before {
  content: "";
  border-radius: 2px;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--secondary-color);
  background-color: CanvasText;
}

input[type="checkbox"]:checked::before {
  transform: scale(1);
}
</style>