<template>
  <div class="masonry-wrapper @container/msry w-full h-full overflow-y-auto">
    <div ref="masonryEl" class="masonry w-full opacity-0 transition-opacity duration-1000">
      <div
        class="item-sizer w-[48%] @[39rem]/msry:w-[32%] @3xl/msry:w-[23%] @5xl/msry:w-[18%] @6xl/msry:w-[15%] @[1400px]/msry:w-[13%]">
      </div>
      <div
        class="gutter-sizer w-[4%] @[39rem]/msry:w-[2%] @3xl/msry:w-[2.66%] @5xl/msry:w-[2.5%] @6xl/msry:w-[2%] @[1400px]/msry:w-[1.5%]">
      </div>
      <SingleItem
        class="w-[48%] @[39rem]/msry:w-[32%] @3xl/msry:w-[23%] @5xl/msry:w-[18%] @6xl/msry:w-[15%] @[1400px]/msry:w-[13%]"
        v-for="item in items" :key="item.id" :title="title(item)" :img="item.img" :url3D="item.sheet.model3dUrl"
        :id="item.id" :color="item.color" :category="item.category" @click="onClick(item, $event)" @select="onSelect"
        @added="addItem" @loaded="masonry?.layout()" :selected="item.selected" />
    </div>
  </div>
</template>
<script setup>
import { onMounted, ref } from 'vue';
import { useInfiniteScroll } from '@vueuse/core'
import SingleItem from "@/components/SingleItem.vue";
import Masonry from 'masonry-layout';
import imagesLoaded from 'imagesloaded'
import { useDocumentationStore } from '@/stores/documentation';

const emit = defineEmits(["click", "select", 'load-more']);
const props = defineProps({ items: { type: Array, required: true }, scrollContainer: { type: String } });

const masonryEl = ref(null);
const masonry = ref(null);
const docStore = useDocumentationStore();
const title = (item) => item.title || item.masonryTitle

function onClick(item, panoFull) {
  if (!item.onClick) emit('click', { item: item, panoFull: panoFull });
  else item.onClick();
}
function onSelect(data) { emit("select", data); }

const addItem = (e) => {
  if (masonry.value) masonry.value.appended(e)
}

onMounted(() => {
  imagesLoaded(masonryEl.value).on('always', function () {
    if (masonryEl.value) masonryEl.value.style.opacity = 1
    masonry.value = new Masonry(masonryEl.value, {
      itemSelector: '.masonry-item',
      columnWidth: '.item-sizer',
      percentPosition: true,
      gutter: '.gutter-sizer',
      visibleStyle: { transform: 'translateY(0)', opacity: 1 },
      hiddenStyle: { transform: 'translateY(100px)', opacity: 0 },
    });
  })

  console.log(props.items);

  useInfiniteScroll(props.scrollContainer ? masonryEl.value.closest('.' + props.scrollContainer) : masonryEl.value.parentElement, () => {
    emit('load-more', docStore.pageCounter)
  }, {
    interval: 3000,
  })
})

defineExpose({ masonry, masonryEl })
</script>
